/* eslint-disable @typescript-eslint/no-floating-promises */

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'services/authorization.service';
import { CentralServerService } from 'services/central-server.service';
import { LanguageService } from 'services/language.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { ChargePointStatus, ChargingStation, Connector } from 'types/ChargingStation';
import { Paging, PricingDefinitionDataResult } from 'types/DataResult';
import { FilterParams, QrCodeResponse, RestResponse } from 'types/GlobalType';
import { Utils } from 'utils/Utils';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User, UserNotifications, UserRole, UserStatus, UserWithPassword } from 'types/User';
import { firstValueFrom } from 'rxjs';
import { WindowService } from 'services/window.service';
import { AbstractTabComponent } from 'shared/component/abstract-tab/abstract-tab.component';

@Component({
  selector: 'app-qrcode',
  templateUrl: 'qrcode.component.html',
  styleUrls: ['qrcode.component.scss'],
})
export class QrCodeComponent extends AbstractTabComponent implements OnInit, OnDestroy {

  public canListTags: boolean;
  public pricingDefinitions: any;
  public chargingStation: ChargingStation | null = null;
  public connectorId: number;
  public connectorExists = true;
  public connectorStatus: string;
  public browserLanguage: string;
  public connectorStatusColor: string;
  public chargingStationId: string;
  public decodedHeader: any;
  public connectorTest: Connector;
  public decodedPayload: any;
  cardForm: FormGroup;
  intervalId: any;
  userRole: UserRole;
  isLoggedIn = false;
  userData: UserWithPassword;
  displayedColumns: string[] = [];
  token: string;
  dataLoaded = false;
  public constructor(
    activatedRoute: ActivatedRoute,
    windowService: WindowService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
  ) {
    super(activatedRoute, windowService, ['all']);
  }

  // Getters
  get hasFlatFee(): boolean {
    return this.pricingDefinitions?.flatFee?.active && this.pricingDefinitions.flatFee.price != null;
  }

  get hasEnergy(): boolean {
    return this.pricingDefinitions?.energy?.active && this.pricingDefinitions.energy.price != null;
  }

  get hasChargingTime(): boolean {
    return this.pricingDefinitions?.chargingTime?.active && this.pricingDefinitions.chargingTime.price != null;
  }

  get hasParkingTime(): boolean {
    return this.pricingDefinitions?.parkingTime?.price != null;
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  redirectToPayment(): void {
    const token = this.decodeTokenHeader(this.token);
    if (token.chargingStationID && token.connectorID) {
      this.onSubmit().then(() => {
        if (this.isLoggedIn === true) {
          this.router.navigate([`/qrcode/${this.token}/payment`]);
        } else {
          console.log('No user');
        }
      });
    } else {
      const ConnectorStatusCheck = this.connectorTest.status;
      switch (true) {
        case !token.chargingStationID:
          this.messageService.showWarningMessage('Charging Station ID is missing.');
          break;
        case !token.connectorID:
          this.messageService.showWarningMessage('Connector ID is missing.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.CHARGING:
          this.messageService.showWarningMessage('Connector is already charging.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.UNAVAILABLE:
          this.messageService.showWarningMessage('Connector is Unavailable.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.OCCUPIED:
          this.messageService.showWarningMessage('Connector is Occupied.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.SUSPENDED_EVSE:
          this.messageService.showWarningMessage('EVse suspended.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.FAULTED:
          this.messageService.showWarningMessage('Connector is Faulted.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.RESERVED:
          this.messageService.showWarningMessage('Connector us Reserved.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.FINISHING:
          this.messageService.showWarningMessage('Connector is Finishing.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.SUSPENDED_EV:
          this.messageService.showWarningMessage('EV Suspended');
          break;
        default:
          this.messageService.showWarningMessage('Connector must be plugged in your vehicle.');
      }
    }
  }


  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.checkLoginAndLoadData();
    this.createForm();
    localStorage.setItem('SessionToken', this.token);
  }

  createForm() {
    this.cardForm = this.fb.group({
      email: ['', Validators.email],
    });
  }

  public createAllTrueNotifications = (): UserNotifications => ({
    sendSessionStarted: true,
    sendOptimalChargeReached: true,
    sendEndOfCharge: true,
    sendEndOfSession: true,
    sendUserAccountStatusChanged: true,
    sendNewRegisteredUser: true,
    sendUnknownUserBadged: true,
    sendChargingStationStatusError: true,
    sendChargingStationRegistered: true,
    sendOcpiPatchStatusError: true,
    sendOicpPatchStatusError: true,
    sendUserAccountInactivity: true,
    sendPreparingSessionNotStarted: true,
    sendOfflineChargingStations: true,
    sendBillingSynchronizationFailed: true,
    sendBillingPeriodicOperationFailed: true,
    sendSessionNotStarted: true,
    sendCarCatalogSynchronizationFailed: true,
    sendComputeAndApplyChargingProfilesFailed: true,
    sendEndUserErrorNotification: true,
    sendBillingNewInvoice: true,
    sendAdminAccountVerificationNotification: true,
  });

  public generateRandomPassword(): string {
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digitChars = '0123456789';
    const specialChars = '!#@:;,%&=_<>/\'$^*.-+()';
    const allChars = lowerCaseChars + upperCaseChars + digitChars + specialChars;

    const getRandomChar = (charSet: string) => charSet[Math.floor(Math.random() * charSet.length)];

    let password = '';
    password += getRandomChar(lowerCaseChars);
    password += getRandomChar(upperCaseChars);
    password += getRandomChar(digitChars);
    password += getRandomChar(specialChars);

    for (let i = password.length; i < 8; i++) {
      password += getRandomChar(allChars);
    }

    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    return password;
  }

  public async onSubmit(): Promise<void> {
    const email = this.cardForm.get('email').value || this.generateRandomEmail();
    const password = this.generateRandomPassword();
    if (this.cardForm.controls['email'].value || this.generateRandomEmail()) {
      const userData: UserWithPassword = {
        status: UserStatus.ACTIVE,
        firstName: 'QrCode',
        name: 'QrCode',
        email,
        locale: 'en_US',
        role: UserRole.QRCODE,
        id: '',
        issuer: false,
        fullName: '',
        tags: [],
        plateID: '',
        phone: undefined,
        mobile: '',
        notificationsActive: false,
        notifications: undefined,
        address: undefined,
        iNumber: '',
        costCenter: false,
        image: '',
        language: '',
        numberOfSites: 0,
        scopes: [],
        companies: [],
        sites: [],
        sitesAdmin: [],
        sitesOwner: [],
        userHashID: 0,
        tenantHashID: 0,
        eulaAcceptedHash: '',
        eulaAcceptedVersion: 0,
        eulaAcceptedOn: undefined,
        billingData: undefined,
        technical: false,
        freeAccess: false,
        password,
        acceptEula: true,
      };
      console.log('QrCodeComponent ~ onSubmit ~ userData---------------:', userData);
      try {
        // Step 1: Create User
        const createUserResponse = await firstValueFrom(this.centralServerService.createUserQr(userData));
        console.log('QrCodeComponent ~ onSubmit ~ userData:', userData);
        if (createUserResponse.status !== RestResponse.SUCCESS) {
          this.messageService.showErrorMessage('Failed to create user');
          return;
        }
        await this.login(userData, password);
        const createdUserId = createUserResponse.id;
        userData.id = createdUserId;
        localStorage.setItem('userId', createdUserId);
        console.log('PaymentComponent ~ onSubmit ~ this.userID:', createdUserId);
      } catch (error) {
        this.messageService.showErrorMessage('An unexpected error occurred');
        console.error('Error during user creation or payment method setup:', error);
      }
    } else {
      console.log('Form is invalid or conditions are not accepted');
    }
  }

  private checkLoginAndLoadData(): void {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
    const token = this.decodeTokenHeader(this.token);
    this.authorizationService.getUserRole();
    this.setupLanguagePreferences();
    const entityId = token.chargingStationID;
    const connectorId = Number(token.connectorID);
    if (entityId) {
      this.loadPricingDefinitions(entityId);
      if (connectorId) {
        this.intervalId = setInterval(() => {
          if (connectorId) {
            this.loadChargingStation(entityId, Number(connectorId));
          } else {
            return null;
          }
        }, 5000);
      } else {
        console.error('Connector ID is missing in the URL');
      }
    }
  }

  private setupLanguagePreferences(): void {
    this.browserLanguage = this.languageService.getBrowserLanguage() || 'fr';
    localStorage.setItem('language', this.browserLanguage);
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = this.browserLanguage.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
  }

  private loadPricingDefinitions(entityId: string): void {
    const params: FilterParams = {};
    const paging: Paging = { limit: 10, skip: 0 };
    const ordering = [];

    this.centralServerService.getPricingDefinitionsQRcode(params, paging, ordering, { entityID: entityId, entityType: 'ChargingStation' }).subscribe({
      next: (data: PricingDefinitionDataResult) => {
        if (data && data.count > 0) {
          this.pricingDefinitions = data.result[0].dimensions;
        }
      },
      error: (error) => {
        console.error('Failed to load pricing definitions:', error);
      },
    });
  }

  private loadChargingStation(entityId: string, connectorId: number): void {
    this.centralServerService.getChargingStationQr(entityId).subscribe({
      next: (chargingStation: ChargingStation) => {
        this.chargingStation = chargingStation;
        const chargingStationData = JSON.stringify(this.chargingStation);
        console.log('QrCodeComponent ~ this.centralServerService.getChargingStationQr ~ chargong:', chargingStationData);
        localStorage.setItem('chargingStation',chargingStationData);
        this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
        this.connectorId = connectorId;
        this.connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);
        if (this.connectorTest) {
          const statusKey = `connectorStatus.${this.connectorTest.status.toUpperCase()}`;
          this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
            this.connectorStatus = translatedStatus || this.connectorTest.status;
            this.applyStatusColor(this.connectorTest.status.toUpperCase());
            this.connectorExists = true;
            this.dataLoaded = true;
          });
        } else {
          this.connectorExists = false;
          this.connectorStatus = '';
          this.dataLoaded = false;
        }

        if (!this.connectorExists) {
          this.spinnerService.hide();
        }
      },
      error: (error) => {
        console.error('Failed to load charging station:', error);
        this.spinnerService.hide();
        this.connectorExists = false;
        this.connectorStatus = '';
        this.dataLoaded = false;
      },
    });
  }

  private applyStatusColor(status: string): void {
    const statusColorMap = {
      UNAVAILABLE: 'red',
      AVAILABLE: 'green',
      BLOCKED: 'black',
      CHARGING: 'blue',
      INOPERATIVE: 'gray',
      OUTOFORDER: 'darkred',
      PLANNED: 'purple',
      REMOVED: 'orange',
      RESERVED: 'darkblue',
      UNKNOWN: 'darkgray',
      PREPARING: '#ff8900',
    };
    this.connectorStatusColor = statusColorMap[status] || 'default-color';
  }

  private decodeTokenHeader(token: string): any {

    // Check if the token is non-empty and does not have periods (i.e., no standard JWT format)
    if (!token || token.indexOf('.') === -1) {

      try {
      // Handle single-part token
        const headerBase64Url = token;

        // Convert base64Url to base64
        const base64UrlToBase64 = (base64Url: string): string => base64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Decode base64 to string
        const decodeBase64Url = (base64Url: string): string => {
          let base64 = base64UrlToBase64(base64Url);
          while (base64.length % 4 !== 0) {
            base64 += '=';
          }
          return atob(base64);
        };

        // Decode and parse the header
        const header = decodeBase64Url(headerBase64Url);
        return JSON.parse(header);
      } catch (error) {
        throw new Error('Failed to decode token header');
      }
    } else {
      throw new Error('Invalid token format');
    }
  }

  private generateRandomEmail(): string {
    const randomString = Math.random().toString(36).substring(2, 11);
    return `${randomString}@qrcode.com`;
  }

  private async login(user: User, password: string): Promise<void> {
    this.spinnerService.show();
    this.authorizationService.cleanUserAndUserAuthorization();
    const loginUser: UserWithPassword = {
      email: user.email,
      id: '',
      issuer: false,
      name: '',
      firstName: '',
      fullName: '',
      tags: [],
      plateID: '',
      phone: undefined,
      mobile: '',
      notificationsActive: false,
      notifications: this.createAllTrueNotifications(),
      address: undefined,
      iNumber: '',
      costCenter: false,
      status: UserStatus.ACTIVE,
      image: '',
      role: UserRole.QRCODE,
      locale: '',
      language: '',
      numberOfSites: 0,
      scopes: [],
      companies: [],
      sites: [],
      sitesAdmin: [],
      sitesOwner: [],
      userHashID: 0,
      tenantHashID: 0,
      eulaAcceptedHash: '',
      eulaAcceptedVersion: 0,
      eulaAcceptedOn: undefined,
      billingData: undefined,
      technical: false,
      freeAccess: false,
      password,
      acceptEula: true,
    };
    try {
      const result = await firstValueFrom(this.centralServerService.login(loginUser));
      this.isLoggedIn = true;
      console.log('QrCodeComponent ~ login ~ this.isLoggedIn:', this.isLoggedIn);
      this.spinnerService.hide();
      this.centralServerService.loginSucceeded(result.token);
    } catch (error) {
      this.spinnerService.hide();
      console.error('failed to login:', error);
    }
  }
}


